.pageTitle {
  margin-bottom: 10px !important;
  color: #303133 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.outterContainer {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.statusTextWithBg {
  font-size: 14px;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 30px;
  width: fit-content;
}

.statusTextWithBgRed {
  color: #f56c6c;
  background: #fef0f0;
}

.statusTextWithBgOrange {
  color: #e6a23c;
  background: #fdf6ec;
}

.statusTextWithBgBlue {
  color: #409eff;
  background: #ecf5ff;
}

.statusTextWithBgGrey {
  color: #909399;
  background: #f4f4f5;
}

.statusTextWithBgGreen {
  color: #67c23a;
  background: #f0f9eb;
}

.modalTitleContainer {
  color: #303133;
  font-weight: 500;
  font-size: 18px;
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.modalClearBtn {
  /* cursor: pointer; */
  display: flex !important;
  justify-content: flex-end !important;
  color: #303133 !important;
  min-width: 0px !important;
  padding: 6px !important;
  border-radius: 100% !important;
}

.modalFormElem {
  margin: 15px 0;
}

.modalFormBtnContainer {
  text-align: right;
}

.modalSubmitBtn {
  color: #1989fa !important;
}

.divider {
  border: 1px solid #d8dce6;
  height: 45px;
}

.buttonAction {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 10px !important;
  color: #ffffff !important;
  padding: 2px;
}

.buttonActionBlue {
  background: #00c0ef !important;
  border: 1px solid #00acd6 !important;
  box-sizing: border-box;
  border-radius: 4px;
}

.buttonActionBlue:hover {
  background: #02b4e0;
}

.buttonActionGreen {
  background: #00bfa5 !important;
  border: 1px solid #09ad97 !important;
  box-sizing: border-box;
  border-radius: 4px;
}

.buttonActionGreen:hover {
  background: #02a08b;
}

.buttonActionDarkBlue {
  background: #338df7 !important;
  border: 1px solid #0271f5 !important;
  box-sizing: border-box;
  border-radius: 4px;
}

.buttonActionOrange {
  background-color: #f39c12 !important;
}

.buttonActionOrange:hover {
  background-color: #e08e0b !important;
}

.buttonActionRed {
  background-color: #dd4b39 !important;
}

.buttonActionGrey {
  background-color: #9d9da1 !important;
}

.buttonActionGrey:hover {
  background-color: #8a8a8f !important;
}

.searchInput {
  margin-left: 10px;
  width: 120px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  border-radius: 3px;
}

.buttonGroupSent {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #0277bd !important;
  border: 1px solid #0277bd !important;
  border-radius: 4px;
  margin-left: 10px !important;
}

.buttonGroups {
  min-height: 48.75px;
}

.fileUploaderContainer {
  margin: 10px 0;
  padding: 20px;
  border: 1px dashed #d8dce6;
  border-radius: 4px;
  color: #9a9da4;
  font-size: 20px;
  font-weight: 500;
}

.fileUploaderContainer:hover {
  cursor: pointer;
}

.fileUploaderDragAndDropElem {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.fileUploaderDragAndDropBrowse {
  margin-left: 5px;
  color: #1989fa;
}

.fileUploaderAttachmentContainer {
  background: #f8f9fa;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.fileUploaderAttachmentLeftElem {
  display: flex;
  align-items: center;
}

.fileUploaderAttachmentRightElem {
  color: #606266 !important;
  cursor: pointer;
}

.fileUploaderAttachmentBlue {
  display: flex;
  align-items: center;
  color: #1989fa !important;
}

.fileUploaderAttachmentIcon {
  margin-right: 5px;
}

.fileUploaderAttachmentFileSize {
  color: #606266;
  margin-left: 5px;
}
