.registerPageForgotPasswordContainer {
  text-align: right;
  color: #888888;
  text-decoration: underline;
  font-size: 16px;
}

.registerPageForgotPasswordSpan:hover {
  cursor: pointer;
}

.registerPageRegisterContainer {
  text-align: center;
  color: #888888;
  text-decoration: underline;
  font-size: 16px;
}

.registerPageRegisterSpan {
  font-size: 16px !important;
  color: #888888 !important;
  text-decoration: underline !important;
  text-decoration-color: rgba(136, 136, 136, 0.4) !important;
}

.registerPageRegisterBtn {
  color: #ffffff !important;
  background-color: #888888 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 20px !important;
  width: 100%;
  height: 100%;
  border-color: #888888 !important;
  text-transform: none !important;
}

.registerPageTitle {
  color: #888888;
  display: flex;
  justify-content: center;
}

.registerPageApplicantId {
  color: #888888;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.registerPageApplicantMsg {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #888888;
}

.registerPageApplicantEmail {
  text-decoration: underline;
}

.submissionDescription {
  color: #888888;
  font-size: 16px;
}

.phoneNumberContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
}

.submissionNormalText {
  color: #888888 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.compulsoryDesc {
  color: #888888 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center;
}

.textRed {
  color: #d32f2f !important
}