.udateApplicantStatusButton_DownloadBtnContainer {
  display: flex;
  align-items: center;
  text-transform: none;
  color: #1989fa;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.udateApplicantStatusButton_DownloadBtn {
  color: #1989fa !important;
}
