.createMessageDragAndDropTitle {
  color: #606266;
}

.createMessageSendBtn {
  background-color: #409eff !important;
  color: #ffffff !important;
  margin-left: 8px !important;
  border-radius: 4px !important;
  min-height: 30.75px !important;
}

.createMessageScheduleBtn {
  margin-left: 8px !important;
  border-radius: 4px !important;
  min-height: 30.75px !important;
}

.createMessageCancelBtn {
  color: #606266 !important;
  margin-left: 8px !important;
  border: 1px solid #606266 !important;
  border-radius: 4px !important;
  min-height: 30.75px !important;
}

.createMessageDateTimeContainer {
  display: flex;
}

.createMessageTimeContainer {
  display: flex;
  flex-direction: column;
}

.createMessageEnterTime {
  font-size: 16px;
  color: #717370;
  font-weight: 500;
  font-size: 10px;
  margin: 10px;
}

.scheduleHours {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 20px;
}

.scheduleMinutes {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 50px;
}

.scheduleDescription {
  font-size: 16px;
  color: #717370;
  margin-bottom: 20px;
}

.blueTextForSchedule {
  color: #1989fa;
  margin-left: 5px;
}

.marginLeft5 {
  margin-left: 5px;
}

.createMessageTextContainer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
}
