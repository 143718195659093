.forgotPasswordImageContainer {
  background-color: #888888;
  border-radius: 20px;
  padding: 15px !important;
  word-wrap: break-word;
}

.forgotPasswordTitle {
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.forgotPasswordExplain {
  margin: 10px 0;
  font-size: 16px;
  text-align: center;
}

.forgotPasswordSubTitle {
  color: #888888;
}
