.viewApplicationForm_FormTypeContainer {
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.viewApplicationForm_EmptyFormTypeContainer {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.viewApplicationForm_FormTypeTitle {
  font-weight: 500;
  font-size: 20px;
  /* padding: 0 !important; */
  color: #303133;
  text-align: left;
}

.viewApplicationForm_InfoBoxSpecial {
  background: #ffffff;
  border: 1px solid #d8dce6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 8px 8px 8px;
  text-align: left !important;
  margin-top: 16px;
}

.viewApplicationForm_InfoBox {
  background: #ffffff;
  border: 1px solid #d8dce6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  text-align: left !important;
  margin-top: 16px;
}

.viewApplicationForm_InfoBoxTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: #606266;
  font-size: 14px;
}

.viewApplicationForm_FormTitle {
  font-weight: 500;
  font-size: 20px;
  padding: 0 !important;
  text-align: left;
  color: #1989fa;
}

.viewApplicationFormTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #303133;
  text-align: left;
}

.viewApplicationFormApplicationContainer {
  background-color: #ffffff !important;
  padding: 20px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
}

.viewApplicationForm_RowMargin {
  margin: 5px 0;
}

.viewApplicationForm_InfoBoxProgressElem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: #606266;
  margin-top: 10px;
  font-size: 16px;
}

.viewApplicationForm_ApplicantLimitAndAvailableDate {
  color: #606266;
  font-size: 16px;
}

.viewApplicationForm_InfoBoxStatusContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  padding-top: 2%;
}

.viewApplicationForm_InfoBoxStatusElem {
  font-size: 24px !important;
  font-weight: 500;
  letter-spacing: 0.15px;
  text-align: center;
}

.viewApplicationForm_ViewBtn {
  background: #00bfa5 !important;
  border: 1px solid #09ad97 !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  width: 100% !important;
}

.viewApplicationForm_CreateBtn {
  margin-top: 16px;
}

.viewApplicationForm_CreateBtnText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
}
