.MuiButton-outlined.applicationFormListButton {
  color: #0277bd !important;
}

.applicatonFormListLinkIcon {
  margin-right: 5px;
}

.applicationFormListButtonText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
}

.applicationFormListViewBtn {
  background-color: #00bfa5 !important;
}

.applicationFormListDuplicateBtn {
  background-color: #338df7 !important;
}

.applicationFormListDeleteBtn {
  background-color: #dd4b39 !important;
}
