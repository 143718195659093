.applicationListTitle {
  color: #888888;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 10px;
}

.applicationListContainer {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 10px 0;
  cursor: pointer;
}

.applicationListContainerInside {
  display: flex;
  align-items: center;
}

.applicationListIncompleteStatus {
  display: inline;
  margin-left: 5px;
  background-color: #ffc5b4;
  color: white;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 12px;
}

.applicationListCompletedStatus {
  display: inline;
  margin-left: 5px;
  background-color: #b5e98c;
  color: white;
  border-radius: 20px;
  padding: 2px 10px;
  font-size: 12px;
}

.applicationListDetailsContainer {
  margin-left: 10px;
}

.applicationListDetailsTitle {
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  color: #4a4a4a;
  margin-bottom: 5px;
}

.applicationListDetailsPeriod {
  font-size: 12px;
  line-height: 14px;
  color: #4a4a4a;
}

.applicationListDetailsBtn {
  color: #e0e0e0;
}

.applicationListLeaveBtn {
  color: #ffffff !important;
  background-color: #888888 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 20px !important;
  /* width: 100%; */
  border-color: #888888 !important;
}
