.viewApplicationFormDataGrid_InfoBox {
  background: #ffffff;
  border: 1px solid #d8dce6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #606266;
  text-align: left;
}

.buttonGroupBatchDownload {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff !important;
  background: #00bfa5 !important;
  border: 1px solid #09ad97 !important;
  border-radius: 4px;
  margin-left: 10px !important;
}

.menuButton {
  font-weight: 500;
  font-size: 14px;
  /* text-transform: uppercase; */
  color: #ffffff;
  background: #ffffff !important;
  border: 1px solid #4e4e4e60 !important;
  border-radius: 4px;
}

.menuButton:hover {
  background: #999999ba !important;
  border: 1px solid #334892ba !important;
}

.buttonGroupAccept {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background: #338df7 !important;
  border: 1px solid #0271f5 !important;
  border-radius: 4px;
  margin-left: 10px !important;
}

.buttonGroupWaiting {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background: #f7a233 !important;
  border: 1px solid #e08e0b !important;
  border-radius: 4px;
  margin-left: 10px !important;
}

.buttonGroupReject {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background: #f75a51 !important;
  border: 1px solid #f53b31 !important;
  border-radius: 4px;
  margin-left: 10px !important;
}

.buttonGroupPending {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background: #909399 !important;
  border: 1px solid #909399 !important;
  border-radius: 4px;
  margin-left: 10px !important;
}

.modalDatePickerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.datePickerRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  gap: 4px;
}

.datePicker {
  width: 50%;
}