.loginPageForgotPasswordContainer {
  text-align: right;
  color: #888888;
  text-decoration: underline;
  font-size: 16px;
}

.loginPageForgotPasswordSpan {
  font-size: 16px !important;
  color: #888888 !important;
  text-decoration: underline !important;
  text-decoration-color: rgba(136, 136, 136, 0.4) !important;
}

.loginPageRegisterContainer {
  text-align: center;
  color: #888888;
  text-decoration: underline;
  font-size: 16px;
}

.loginPageRegisterSpan {
  font-size: 16px !important;
  color: #888888 !important;
  text-decoration: underline !important;
  text-decoration-color: rgba(136, 136, 136, 0.4) !important;
}

.loginPageLoginBtn {
  color: #ffffff !important;
  background-color: #888888 !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 20px !important;
  width: 100%;
}
