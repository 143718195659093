.WithoutHeight {
  height: 100px !important;
}

.whiteText16 {
  color: Black !important;
  font-size: 16px !important;
}

.widthFullWidth {
  width: 100% !important;
  height: 30px !important ;
  border: Black solid 2px !important;
  border-radius: 20px !important;
}

.transparentBackground {
  background-color: rgba(255, 255, 255, 0) !important;
}
.marginTop50p {
  margin-top: 50px !important;
}

.borderWhite {
  border: Black solid 2px;
  border-radius: 20px !important;
  color: Black;
}

.autoWidth {
  width: 70%;
  margin-left: 2%;
  height: 30px;
  border: Black solid 2px;
  border-radius: 20px;
}

.height40 {
  height: 40px !important;
}

.margin10 {
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 20px !important;
  height: 40px;
}

.bold {
  font-weight: 900 !important;
}

.margin5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.imgContainer {
  background: rgb(136, 136, 136, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: break-word;
  margin-bottom: 5px;
  padding: 3px 5px 3px 15px;
  border: 1px solid rgb(136, 136, 136, 0.25);
  border-radius: 100px;
}

.imgText {
  color: #888888;
}

.fileNameContainer {
  position: relative;
  width: 100%;
  background-color: #9fbfdd98;
  color: Black;
  padding: 10px;
  border-radius: 30px;
}

.fileDelete {
  position: absolute;
  top: 5px;
  right: 5px;
}
.imgWidth {
  width: 100%;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100% !important;
}

.btn {
  width: 100% !important;
  height: 30px !important;
  border: 2px solid Black !important;
  border-radius: 20px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  color: Black !important;
  font-size: 16px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100% !important;
}

.buttonUnderStepper {
  background-color: Black !important;
  margin: 5px !important;
  color: #29b6f6 !important;
  border-radius: 20px !important;
}

.upload-btn-wrapper2 {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100% !important;
  border-radius: 1px solid Black !important;
}

.btn2 {
  position: absolute;
  left: 0;
  top: 0;
}

.submissionAllowMultipleAnsContainer {
  display: flex;
  align-items: center;
  color: #888888;
  margin-top: 5px;
  font-size: 14px;
}

.submissionAllowMultipleAnsBtn {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.submissionAllowMultipleAnsText {
  margin-left: 5px;
}

.submissionFormUploadBtn {
  color: #888888 !important;
  border-radius: 20px !important;
  width: 100%;
  border-color: #888888 !important;
  cursor: pointer;
}

.submissionFormUploadBtnWithError {
  color: #d32f2f !important;
  border-radius: 20px !important;
  width: 100%;
  border-color: #d32f2f !important;
  cursor: pointer;
}

.error {
  color: #d32f2f !important;
}

.submissionTitle {
  color: #888888 !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}

.submissionDescription {
  color: #888888;
  font-size: 16px;
}

.submissionSessionDescription {
  color: #888888;
  font-size: 16px;
}

.submissionsectionTitle {
  color: #888888;
  font-size: 20px;
  font-weight: 600;
}

.questionTitle {
  color: #888888 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  white-space: normal !important;
  overflow: auto !important;
}

.questionTitleError {
  color: #d32f2f !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  white-space: normal !important;
  overflow: auto !important;
}

.questionDescription {
  color: #888888 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 2px;
}

.questionLink {
  margin-bottom: 2px;
}

.submissionNormalText {
  color: #888888 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.submissionMultipleAns {
  margin: 10px 0;
}

.formHelperText {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
}

.submissionFormActiveStep {
  display: block;
}

.submissionFormInactiveStep {
  display: none;
}

.submissionBtn {
  color: #ffffff !important;
  background-color: #63b44a !important;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043) !important;
  border-radius: 20px !important;
  width: 100%;
  height: 100%;
  border-color: #63b44a !important;
  text-transform: none !important;
}

.link {
  width: 100%;
  overflow: scroll;
}