.applicationRouterBackgroundImg {
  /* background: url(../../images/SPC_Image.png); */
  background-size: 100vw 100vh;
}

.applicationRouterBackground {
  /* background: linear-gradient(135deg, rgb(5, 175, 224, 0.75) 0%, rgb(2, 119, 189, 0.75) 100%); */
  background: #fbf9f8;
  mix-blend-mode: normal;
  padding-top: 1px;
  min-height: 100vh;
}
