.draftStatus {
  background-color: #f4f4f5;
  color: #909399;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.scheduledStatus {
  background-color: #fdf6ec;
  color: #e6a23c;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.inReviewStatus {
  background-color: #ecf5ff;
  color: #409eff;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.notSendToAllStatus {
  background-color: #fdf3e6;
  color: #ff7a00;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.sentStatus {
  background-color: #f0f9eb;
  color: #67c23a;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.declinedStatus {
  background-color: #fef0f0;
  color: #f56c6c;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
