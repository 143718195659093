/*CreateApplicationForm.tsx */
.formInfoContainer {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  /* padding: 10px 20px 20px 20px; */
}

.formFooterBtn {
  margin-left: 10px !important;
}

.formCancelBtn.MuiButton-outlined {
  color: #606266;
  border-color: #606266;
}

/* FieldsRootComponent.tsx */
.newPageContainer {
  background-color: #edf7ed;
  color: #4caf50;
  text-align: center;
  font-weight: 600;
  padding: 2px;
  margin-bottom: 15px;
}

/* Form Content Select Menu */
.inactiveLinkBtnForQuestions {
  background-color: #ffffff;
  border: 1px solid #d8dce6 !important;
  color: #909399;
  box-shadow: none;
}

.activeLinkBtnForQuestions {
  background-color: #1989fa !important;
  border: 1px solid #1989fa !important;
  color: #ffffff !important;
  box-shadow: none;
}

.activeLinkBtnForQuestions:hover {
  background-color: #73b6fa !important;
  border: 1px solid #73b6fa !important;
  color: #ffffff !important;
  box-shadow: none;
}

.addBtn:hover.MuiButton-root:hover {
  background: rgba(25, 137, 250, 0.1);
  border: 1px solid rgba(25, 137, 250, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
}

.addBtnActive.MuiButton-root {
  background: rgba(25, 137, 250, 0.1);
  border: 1px solid rgba(25, 137, 250, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 0;
}

.MuiMenuItem-root:hover.MuiMenuItem-gutters:hover {
  background: rgba(64, 158, 255, 0.05);
}

.MuiMenuItem-root.MuiMenuItem-gutters {
  font-size: 12px;
}

.activeFormContentQuestionContainer {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background: rgba(25, 137, 250, 0.04);
  border: 1px solid rgba(25, 137, 250, 0.5);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #409eff;
}

.activeFormContentSectionContainer {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background: rgba(25, 137, 250, 0.04);
  border: 1px solid rgba(25, 137, 250, 0.5);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #b26fd4;
}

.inactiveFormContentQuestionContainer {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #ffffff;
}

.inactiveFormContentQuestionContainer:hover {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #409eff;
}

.activeFormContentSessionQuestionContainer {
  margin-bottom: 15px;
  padding: 20px 20px 20px 20px;
  background: rgba(25, 137, 250, 0.04);
  border: 1px solid rgba(25, 137, 250, 0.5);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #409eff;
}

.inactiveFormContentSessionQuestionContainer {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background: rgba(25, 137, 250, 0.04);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid rgba(25, 137, 250, 0.04);
}

.inactiveFormContentSessionQuestionContainer:hover {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background: rgba(25, 137, 250, 0.04);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #409eff;
}

.inactiveFormContentSessionContainer {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #ffffff;
}

.inactiveFormContentSessionContainer:hover {
  margin-bottom: 15px;
  padding: 5px 20px 20px 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #b26fd4;
}

.activeFormContentDescriptionContainer {
  margin-bottom: 15px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #f48fb1;
}

.inactiveFormContentDescriptionContainer {
  margin-bottom: 15px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #ffffff;
}

.inactiveFormContentDescriptionContainer:hover {
  margin-bottom: 15px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border-left: 4px solid #f48fb1;
}

.circleNoContainer {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #409eff;
  border: 1px solid #409eff;
}

.sessionCircleNoContainer {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b26fd4;
  border: 1px solid #b26fd4;
}

.descriptionCircleNoContainer {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f48fb1;
  border: 1px solid #f48fb1;
}

/* Single Choice Questions */
.singleChoiceAddMessageContainer {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.singleChoiceAddOthersMessage {
  color: #1989fa;
}

.singleChoiceAddMessageContainer:hover {
  cursor: default;
}

.singleChoiceAddMessage {
  min-width: 0px !important;
  padding: 0 8px 0 0 !important;
  text-transform: none !important;
  color: black !important;
}

.singleChoiceAddOthersMessage {
  min-width: 0px !important;
  padding: 0 0 0 8px !important;
  text-transform: none !important;
  color: black !important;
}

.singleChoiceAddMessage:hover,
.singleChoiceAddOthersMessage:hover {
  cursor: pointer;
  background-color: white !important;
}

/* Yes No Questions */
.checkBoxOutlineBlankIcon {
  background: #dcdfe6;
  width: 20px;
  height: 20px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
}

.yesNoCaption {
  margin-left: 10px;
  color: #606266;
}

.yesNoAddPointBtnContainer {
  padding: 2px 10px !important;
  border-radius: 40px !important;
  min-height: 30px;
}

.yesNoAddPointBtnElem {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 100px;
  font-size: 12px;
}

.yesNoAddPointBtn {
  display: flex;
}

.yesNoRemovePointBtn {
  display: flex;
  margin-left: 5px;
}

.yesNoQuestionsContainer {
  margin: 10px;
  display: flex;
  align-items: center;
}

.yesNoQuestionsElem {
  margin-left: 5px;
}

.yesNoModalMenuItem {
  margin-right: 15px;
}

.yesNoModalTitle {
  margin-bottom: 10px;
  color: #303133;
  font-weight: 500;
  font-size: 18px;
}

.yesNoModal {
  margin-bottom: 20px;
  color: #606266;
  font-size: 14px;
}

.yesNoSelectedContainer {
  background-color: rgba(64, 158, 255, 0.05);
  padding: 10px;
  margin-bottom: 20px;
}

.yesNoSelectedTitle {
  color: #606266;
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: 300;
}

.yesNoSelectedAns {
  color: #606266;
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: 500;
}

.linkIcon {
  cursor: pointer;
}

.linkClearIcon {
  cursor: pointer;
  margin-left: 5px;
}

.linkedDesc {
  color: #1976d2;
}

.linkedQuestionContainer {
  display: flex;
  align-items: center;
}

.singleChoiceDesc {
  display: flex;
  color: #1976d2;
  margin-left: 10px;
  align-items: center;
}

.singleChoiceDescClearIcon {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

/* drag */
.dragIndicatorContainer {
  text-align: center;
}

.dragIndicatorIcon {
  color: #d8dce6;
  transform: rotate(90deg);
}

.singleChoiceAddOthers {
  margin: 10.25px 0;
}

.singleChoiceRadioButtonUncheckedIcon {
  color: #dcdfe6;
}

.singleChoiceLinkRemoveIcon {
  color: #909399;
  cursor: pointer;
}

.singleChoiceLinkRoundedIcon {
  cursor: pointer;
}

.multipleAnswerSetContainer {
  padding: 10px 0;
  font-size: 14px;
}

.multipleAnswerSetCircleNoContainer {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #c4c4c4;
  border: 1px solid #c4c4c4;
}

/* Field.tsx */
.activeInnerFormContentContainer {
  margin-bottom: 15px;
  padding: 20px 10px;
  background: rgba(25, 137, 250, 0.04);
  border: 1px solid rgba(25, 137, 250, 0.5);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.inactiveInnerFormContentContainer {
  background-color: #fafafa;
  padding: 0px 5px 20px 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  /* border-left: 4px solid #409EFF; */
  margin-bottom: 15px;
}

.newPageClearBtn {
  cursor: pointer;
}

.tickIcon {
  color: #909399;
}

.createApplicationFormFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  min-width: 100vw;
  z-index: 9999;
  overflow-y: hidden;
  background-color: #ffffff;
}
